nav.lang, footer, .icon-before svg, img, .switch_theme {
  display: none;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

html {
  font-family: "Lato", sans-serif;

  --color-black: #201f1e;
  --color-text-light: #8c8b8a;
  --color-main: #0c93a0;

  color: var(--color-black);
}

body {
  font-size: 15pt;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 20pt;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 4px;
  }

  h2 {
    font-size: 16pt;
    font-weight: 300;
    text-transform: uppercase;
  }
}

.contact > * {
  margin: 0 0 2pt;
}

a {
  text-decoration: none;
}

a[href^="http"]::after {
  content: " (" attr(href) ") ";
  font-size: .9em;
}

.contact a[href^="http"]::after {
  content: none;
}

figure {
  margin: 0;
}